<template>
  <v-dialog v-model="dialog" max-width="1000px" eager origin="center">
    <v-form ref="form">
      <v-card>
        <v-card-title>
          Conta A Receber
          <v-btn
            @click="handleViewNegotiations()"
            class="ml-auto text-capitalize"
            text
            color="primary"
            v-if="bill_receivable.origin == 'negotiation'"
            >Ver Renegociação</v-btn
          ></v-card-title
        >
        <v-card-text>
          <h3 class="mt-2 mb-2">Detalhes</h3>
          <v-row>
            <v-col>
              <small class="text-secondary">Empresa</small>
              <br />
              <span class="text--primary">
                {{ bill_receivable.company.name }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small class="text-secondary">Descrição</small>
              <br />
              <span class="text--primary">
                {{ bill_receivable.description }}</span
              >
              <app-shortcut-btn
                name="SaleView"
                v-if="bill_receivable.origin == 'sale'"
                :params="bill_receivable.sale_id"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small class="text-secondary">Plano de contas</small>
              <br />
              <span class="text--primary">
                {{ bill_receivable.account_plan.name }}</span
              >
            </v-col>
          </v-row>
          <v-row v-if="bill_receivable.details">
            <v-col>
              <small class="text-secondary">Detalhes</small>
              <br />
              <span class="text--primary" style="white-space: pre">
                {{ bill_receivable.details }}</span
              >

              <template v-if="bill_receivable.origin == 'negotiation'">
                <br />
                <span>
                  Motivo da Renegociação:
                  {{ bill_receivable.negotiation.motive }}
                </span>
                <br />
                <span>
                  Detalhes da Renegociação:
                  {{ bill_receivable.negotiation.notes }}
                </span>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <small class="text-secondary">Documento</small>
              <br />
              <span class="text--primary"> {{ bill_receivable.document }}</span>
            </v-col>
            <v-col>
              <small class="text-secondary">Vencimento</small>
              <br />
              <span class="text--primary">
                <app-due-date-label :due-date="bill_receivable.due_date"
              /></span>
            </v-col>
            <v-col>
              <small class="text-secondary">Valor</small>
              <br />
              <span class="text--primary">
                {{ $format.decimal(bill_receivable.value) }}</span
              >
            </v-col>
            <v-col>
              <br />
              <BillPayableStatusChip :value="bill_receivable.status" />
            </v-col>
          </v-row>
          <template v-if="hasPayments">
            <h3 class="mt-6">Pagamentos</h3>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="bill_receivable.bill_receivable_payments"
                  hide-default-footer
                  disable-sort
                >
                  <template v-slot:[`body.append`]>
                    <tr>
                      <td>Totais:</td>
                      <td></td>
                      <td class="text-right">
                        <b>{{ $format.decimal(bill_receivable.paid_value) }}</b>
                      </td>
                      <td class="text-right">
                        <b>{{ $format.decimal(bill_receivable.discount) }}</b>
                      </td>
                      <td class="text-right">
                        <b>{{ $format.decimal(bill_receivable.addition) }}</b>
                      </td>
                      <td class="text-right">
                        <b>{{ $format.decimal(bill_receivable.interest) }}</b>
                      </td>
                      <td class="text-right">
                        <b>{{
                          $format.decimal(bill_receivable.net_paid_value)
                        }}</b>
                      </td>
                      <td></td>
                    </tr>
                  </template>
                  <!-- <template v-slot:[`item.account`]="{ item }"> -->
                  <!-- {{item.bill_receivable_payments}} -->
                  <!-- <AccountLabel :account="item.account_transaction.account" /> -->
                  <!-- </template> -->
                  <!-- <template v-slot:[`item.company`]="{ item }">
                  <company-chip :company="item.company" />
                </template>
                <template v-slot:[`item.supplier_description`]="{ item }">
                  {{ item.description }}
                  <span v-if="item.supplier">| {{ item.supplier.name }}</span>
                </template>-->
                  <template v-slot:[`item.paid_at`]="{ item }">
                    {{ $format.dateBr(item.paid_at) }}
                  </template>
                  <template v-slot:[`item.paid_value`]="{ item }">
                    {{ $format.decimal(item.paid_value) }}
                  </template>
                  <template v-slot:[`item.discount`]="{ item }">
                    {{ $format.decimal(item.discount) }}
                  </template>
                  <template v-slot:[`item.addition`]="{ item }">
                    {{ $format.decimal(item.addition) }}
                  </template>
                  <template v-slot:[`item.interest`]="{ item }">
                    {{ $format.decimal(item.interest) }}
                  </template>
                  <template v-slot:[`item.net_paid_value`]="{ item }">
                    {{ $format.decimal(item.net_paid_value) }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="printReceipt(item)" color="primary">
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <!-- <template v-slot:[`item.due_date`]="{ item }">
                  <app-due-date-label :due-date="item.due_date" />
                </template> -->
                  <template v-slot:[`item.status`]="{ item }">
                    <BillPayableStatusChip :value="item.status" />
                  </template>
                </v-data-table>
              </v-col> </v-row
          ></template>
        </v-card-text>
        <v-card-actions
          class="justify-end py-4"
          style="background-color: #f5f5f5"
        >
          <v-btn
            @click="dialog = false"
            text
            class="text-capitalize"
            color="secondary"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <BillReceivableNegotiationDialog ref="BillReceivableNegotiationDialog" />
  </v-dialog>
</template>

<script>
import AccountLabel from "@/components/account/sections/AccountLabel.vue";
import BillPayableStatusChip from "@/components/bill-payable/sections/BillPayableStatusChip.vue";
import BillReceivableNegotiationDialog from "@/components/bill-receivable/sections/BillReceivableNegotiationDialog";

export default {
  components: {
    AccountLabel,
    BillPayableStatusChip,
    BillReceivableNegotiationDialog,
  },
  data: () => ({
    dialog: false,
    id: null,
    bill_receivable: {
      company: {},
      account_plan: {},
      negotiation: {},
      bill_receivable_payments: [],
    },
    headers: [
      // { text: "Conta", value: "account" },
      { text: "Data do Pg", value: "paid_at" },
      { text: "Valor Pago", value: "paid_value", align: "right" },
      { text: "Desconto", value: "discount", align: "right" },
      { text: "Acréscimo", value: "addition", align: "right" },
      { text: "Juros", value: "interest", align: "right" },
      { text: "Valor Líquido Pg.", value: "net_paid_value", align: "right" },
      { text: "Status", value: "status", align: "center" },
      { text: "", value: "actions", align: "center" },
    ],
  }),

  created() {
    // this.open(7);
  },

  computed: {
    hasPayments() {
      return this.bill_receivable.bill_receivable_payments.length > 0;
    },
  },

  methods: {
    async open(id) {
      this.id = id;
      await this.loadBillPayable();
      this.dialog = true;
    },

    async loadBillPayable() {
      let response = await this.$http.show(
        "bill-receivable/bill-receivable",
        this.id
      );

      this.bill_receivable = response.bill_receivable;
    },
    handleViewNegotiations() {
      let negotiation_id = this.bill_receivable.negotiation_id_as_new
        ? this.bill_receivable.negotiation_id_as_new
        : this.bill_receivable.negotiation_id_as_old;

      this.$refs.BillReceivableNegotiationDialog.open(negotiation_id);
    },
    printReceipt(bill_receivable) {
      this.$print.preview({
        origin_id: bill_receivable.bill_receivable_payment_group_id,
        origin: "bill_receivable_group",
      });
    },
  },
};
</script>

<style>
</style>