
import store from '../store'

const acl = {

  can(permission) {
    if (this.isSuperAdmin()) {
      return true
    }

    return this.hasPermission(permission)
  },

  hasPermission(permission) {
    let permissionArr = this.getPermission(permission)

    if (permissionArr) {
        return true
    }

    return false
  },


  getPermission(permission) {
    try {
      return store.state.auth.userData.user.permissions.filter((row) => row.name.trim() == permission.trim())[0]
    } catch (e) {
      return false
    }
  },

  isSuperAdmin() {
    return store.state.auth.userData.user.super_admin == 1
  },


  isSalesman() {
    return store.state.auth.userData.user.position == 'vendedor'

  },

  isManager() {
    return store.state.auth.userData.user.position == 'gerente'
  },

  isCashier() {
    return store.state.auth.userData.user.position == 'caixa'
  },

  isAdministrativeAssistant() {
    return store.state.auth.userData.user.position == 'auxiliar_administrativo'
  }

}

export default acl;