<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view />
    </component>
    <app-loading />
    <error-handler />
    <AppSnackbar />
    <AppAlert />
  </v-app>
</template>

<script>
import AppAlert from "@/components/app/sections/AppAlert";
import AppSnackbar from "@/components/app/sections/AppSnackbar";
import AppLayout from "@/layouts/AppLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import WhiteLayout from "@/layouts/WhiteLayout.vue";
import PrintLayout from "@/layouts/PrintLayout.vue";

export default {
  components: {
    AppLayout,
    AuthLayout,
    AppAlert,
    PrintLayout,
    AppSnackbar,
    WhiteLayout,
  },

  computed: {
    layout() {
      return this.$route.meta.layout;
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  created() {
    window.addEventListener("offline", (e) => {
      this.$store.commit("app/setIsOffline", true);
    });
    window.addEventListener("online", (e) => {
      this.$store.commit("app/setIsOffline", false);
    });
  },
};
</script>
<style>
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap"); */

* {
  /* font-family: "Quicksand", sans-serif !important; */
  /* font-family: "Source Sans Pro", sans-serif; */
  font-family: "Poppins", sans-serif;

  /* desabilita o recurso Pull-to-Refresh  */
  /* overscroll-behavior: contain; */
  /* overscroll-behavior-y: contain; */
}

/* @font-face {
  font-family: "Quicksand", sans-serif !important;
  src: url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap")
    format("opentype");
} */
</style>