<template>
  <div>
    <template v-if="account.type == 'bank'">
      <span
        :class="[icons.bank[account.bank_code].icon, 'fa-4x ml-1']"
        :style="'font-size: 18px; color: ' + icons.bank[account.bank_code].color"
      ></span>
    </template>
    <template v-else>
      <v-icon color="info">mdi-piggy-bank-outline</v-icon>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    account: {},
  },

  data: () => ({
    icons: {
      local: {
        // icon: "",
        // color: "",
      },
      bank: {
        1 : {
          icon : 'ibb-banco-brasil',
          color : '#003882',
        },
        4 : {
          icon : 'ibb-banco-nordeste' ,
          color : '#a80a35',
        },
        104 : {
          icon : 'ibb-caixa' ,
          color : '#016db8',
        },
        756 : {
          icon : 'ibb-sicoob' ,
          color : '#003641',
        },
      },
    },
  }),
};
</script>

<style>
</style>