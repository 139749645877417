<template>
  <i>
    <v-tooltip bottom v-for="(status, index) in statuses" :key="index">
      <template v-slot:activator="{ on, attrs }">
        <small class="px-1" v-bind="attrs" v-on="on">
          <app-icon
            :color="status.color"
            v-on="$listeners"
            v-bind="$attrs"
            v-text="status.icon"
          />
        </small>
      </template>
      <span>{{ status.text }}</span>
    </v-tooltip>
  </i>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      statusesData: [
        {
          text: "Especial",
          type: "is_special",
          icon: "grade",
          color: "warning",
        },
        { text: "Bloqueado", type: "is_blocked", icon: "lock", color: "red" },
        {
          text: "Analise de Crédito Vencida",
          type: "is_overdue",
          icon: "event_busy",
          color: "warning",
        },
        {
          text: "Tem Débitos",
          type: "has_debit",
          icon: "monetization_on",
          color: "red",
        },
      ],
    };
  },

  computed: {
    creditAnalysis() {
      return this.customer.last_credit_analysis;
    },
    is_special() {
      return this.customer.is_special;
    },

    is_blocked() {
      return this.customer.is_blocked;
    },
    is_overdue() {
      if (this.creditAnalysis) {
        return this.customer.last_credit_analysis.is_overdue || false;
      }
      return false;
    },
    has_debit() {
      return this.customer.has_debit;
    },

    statuses() {
      return this.statusesData.filter((icon) => {
        return this[icon.type];
      });
    },
  },
};
</script>

<style>
</style>