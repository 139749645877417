<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    origin="left top"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="mb-6">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="!$acl.can('customerCreateEdit')"
        >
          <h3 class="mt-4 mb-2">Informações</h3>
          <v-row>
            <v-col cols="7" md="6">
              <v-radio-group
                class="mt-0"
                hide-details="auto"
                v-model="form.person_type"
                row
              >
                <v-radio label="Pessoa Física" value="fisica"> </v-radio>
                <v-radio label="Pessoa Jurídica" value="juridica"> </v-radio>
              </v-radio-group>
            </v-col>
            <v-col offset-md="4" md="2">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <app-text-field v-model="form.name" :label="name_label" />
            </v-col>
            <v-col cols="12" md="6">
              <app-text-field v-model="form.alias" :label="alias_label" />
            </v-col>
          </v-row>
          <template v-if="IsJuridic">
            <v-row>
              <v-col md="6">
                <app-cnpj-field
                  @company="setCompanyData($event)"
                  v-model="form.cnpj"
                  label="CNPJ"
                />
              </v-col>
              <v-col md="4">
                <app-text-field
                  :disabled="form.inscricao_estadual_isento == 1"
                  v-model="form.inscricao_estadual"
                  label="Inscrição Estadual"
                />
              </v-col>
              <v-col md="2">
                <v-checkbox
                  class="mt-0"
                  v-model="form.inscricao_estadual_isento"
                  label="Isento"
                  hide-details="auto"
                  :true-value="1"
                  :false-value="0"
                />
              </v-col>
            </v-row>
          </template>

          <template v-if="!IsJuridic">
            <v-row>
              <v-col md="6">
                <app-cpf-field v-model="form.cpf" label="CPF" />
              </v-col>
              <v-col md="6">
                <app-rg-field v-model="form.rg" label="RG" />
              </v-col>
            </v-row>
          </template>
          <h3 class="mt-10 mb-6">Endereços</h3>
          <AddressForm v-model="form.main_address" />

          <template v-for="(address, index) in form.additional_address">
            <div v-if="address.status == 1" :key="index">
              <AddressForm :value="address" class="my-10" ref="AddressForm" />
            </div>
          </template>

          <v-btn
            text
            class="mt-4 text-capitalize"
            color="secondary"
            @click="addRow()"
          >
            <v-icon>mdi-plus</v-icon> Adicionar
          </v-btn>

          <h3 class="mt-10 mb-4">Telefones</h3>
          <v-row>
            <v-col cols="6">
              <app-text-field v-model="form.phone_wa" label="WhatsApp" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <PhoneContactsForm v-model="form.phones" />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-4">Emails e sites</h3>
          <v-row>
            <v-col>
              <EmailContactsForm v-model="form.emails" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SiteContactsForm v-model="form.sites" />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-4">Dados Adicionais</h3>
          <template v-if="!IsJuridic">
            <v-row>
              <v-col>
                <app-text-field v-model="form.mother_name" label="Filiação" />
              </v-col>

              <v-col>
                <app-text-field v-model="form.profession" label="Profissão" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <app-text-field
                  v-model="form.workplace"
                  label="Local de Trabalho"
                />
              </v-col>

              <v-col>
                <app-text-field
                  type="date"
                  v-model="form.birth_date"
                  label="Data de Nascimento"
                />
              </v-col>

              <v-col>
                <app-number-field v-model="form.income" label="Renda" />
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col>
              <app-text-field
                v-model="form.negative_name"
                label="Nome Negativado"
              />
            </v-col>
            <v-col>
              <app-text-field
                v-model="form.negative_institution"
                label="Instituição Negativa"
              />
            </v-col>
            <v-col>
              <app-date-field
                v-model="form.negative_at"
                label="Data de Negativação"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                auto-grow
                outlined
                rows="1"
                row-height="1"
                v-model="form.alert_notes"
                label="Mensagem de alerta"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                outlined
                auto-grow
                v-model="form.notes"
                label="Observações"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <app-status-switch v-model="form.status" />
            </v-col>
            <v-col>
              <v-switch
                v-model="form.is_special"
                :true-value="1"
                :false-value="0"
                label="Especial"
              >
              </v-switch>
            </v-col>
            <v-col>
              <v-switch
                v-model="form.is_blocked"
                :true-value="1"
                :false-value="0"
                label="Bloqueado"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-5">
          <v-col>
            <v-card color="backgroud" elevation="0">
              <app-activities v-if="!isInsert" :activities="form.activities" />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="$acl.can('customerCreateEdit')"
          @click="handleSave()"
          class="text-capitalize"
          color="primary"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cep from "cep-promise";
import PhoneContactsForm from "@/components/app/forms/PhoneContactsForm.vue";
import EmailContactsForm from "@/components/app/forms/EmailContactsForm.vue";
import SiteContactsForm from "@/components/app/forms/SiteContactsForm.vue";
import AddressForm from "@/components/app/forms/AddressForm.vue";

export default {
  components: {
    PhoneContactsForm,
    EmailContactsForm,
    SiteContactsForm,
    AddressForm,
  },
  data() {
    return {
      dialog: false,
      statusText: {
        1: "Este cadastro está ativo",
        0: "Este cadastro está inativo",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      title: "Cadastrar Cliente",

      valid: false,
      form: {},
      baseForm: {
        code: null,
        person_type: "fisica",
        name: null,
        alias: null,
        cpf: null,
        cnpj: null,
        rg: null,
        status: 1,
        inscricao_estadual: null,
        inscricao_estadual_isento: null,
        is_blocked: 0,
        is_special: 0,
        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city_district: null,
          city: null,
          state: null,
          country: null,
          type: "mainAddress",
        },
        phones: [],
        emails: [],
        sites: [],
        additional_address: [
          {
            address: null,
            addressable_id: null,
            addressable_type: null,
            city: null,
            city_code: null,
            complement: null,
            country: null,
            country_code: null,
            created_at: null,
            district: null,
            id: null,
            number: null,
            postal_code: null,
            state: null,
            state_code: null,
            status: null,
            type: null,
          },
        ],
      },
    };
  },
  computed: {
    IsJuridic() {
      return this.form.person_type == "juridica";
    },
    alias_label() {
      if (this.IsJuridic) {
        return "Nome Fantasia";
      } else {
        return "Apelido";
      }
    },

    name_label() {
      if (this.IsJuridic) {
        return "Razão Social";
      } else {
        return "Nome";
      }
    },
    isInsert() {
      return !this.form.id;
    },
  },
  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();
      if (id) {
        await this.showCustomer(id);
      }
      this.setDefaults();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.title = "Cadastrar Cliente";
    },

    setDefaults() {
      this.form.phones.push({
        contact: null,
      });

      this.form.emails.push({
        contact: null,
      });

      if (this.form.sites.length == 0) {
        this.form.sites.push({
          contact: null,
        });
      }
    },

    async showCustomer(id) {
      this.title = "Atualizar Cliente";
      this.$store.commit("app/showLoading");
      await this.$http.$get("customer/customer/" + id).then((response) => {
        this.form = response.customer;
        this.$store.commit("app/hideLoading");
      });
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("customer/customer", this.form).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("customer/customer", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },

    removeRow(index) {
      this.form.additional_address[index].status = 0;
    },
    addRow() {
      this.form.additional_address.push({
        address: null,
        addressable_id: null,
        addressable_type: null,
        city: null,
        city_code: null,
        complement: null,
        country: null,
        country_code: null,
        created_at: null,
        district: null,
        id: null,
        number: null,
        postal_code: null,
        state: null,
        state_code: null,
        status: 1,
        type: null,
      });
    },

    setCompanyData(company) {
      this.form = {
        ...this.form,
        ...company,
      };
    },
  },
};
</script>

<style scoped>
</style>