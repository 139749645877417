<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    :fullscreen="$app.isMobile"
  >
    <v-card>
      <v-card-title primary-title class="justify-space-between">
        <span>{{ sale.operation.description }} Nº{{ sale.number }}</span>
        <company-chip :company="sale.company" />
      </v-card-title>

      <v-card-subtitle>
        <div>Vendedor: {{ sale.salesman.name }}</div>

        <div v-if="sale.approved_by">
          Aprovada por
          {{ sale.approved_by.short_name }}
          em
          <b>{{ $format.dateTimeBr(sale.approved_at) }}</b>
        </div>
      </v-card-subtitle>

      <v-card-text>
        <v-row v-if="errorMessages && canUserAproveSale()">
          <v-col>
            <v-alert
              v-for="(error, index) in errorMessages"
              :key="index"
              dense
              outlined
              type="error"
            >
              <div class="d-flex justify-space-between">
                <span>{{ error.message }}</span>

                <app-shortcut-btn
                  v-if="error.shortcut_name"
                  :name="error.shortcut_name"
                  :params="sale.customer.id"
                  :query="{ customer_id: sale.customer.id }"
                  @store="loadSale()"
                />
              </div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12">
            <h3>
              {{ title }}
              <app-shortcut-btn
                v-if="sale.customer"
                name="StoreCustomerForm"
                :params="sale.customer.id"
                @store="loadSale()"
              />
            </h3>
          </v-col>

          <v-col v-if="hasCustomer" class="pt-0">
            <app-show-phones :phones="sale.customer.phones" />
          </v-col>
        </v-row>

        <v-divider class="my-4" />
        <h4>
          <v-icon left>mdi-dolly</v-icon>
          Produtos
        </h4>
        <v-row>
          <v-col class="pt-1">
            <v-list dense>
              <template v-for="(sale_product, index) in sale.sale_products">
                <v-list-item two-line :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ sale_product.product.name }}
                      <company-chip
                        class="ml-5"
                        :company="sale_product.product_company.company"
                      />
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      QT {{ $format.decimal(sale_product.quantity) }} x
                      {{ $format.moneyBr(sale_product.price) }}
                      <template v-if="canSeeProfitsMargin()">
                        <br />
                        {{ $format.decimal(sale_product.profit) }}
                        ({{ $format.decimal(sale_product.profit_percent) }}%)
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ $format.moneyBr(sale_product.net_total) }}
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
        <v-row v-if="hasDelivery">
          <v-col class="d-flex justify-end">
            <v-btn @click="handleViewDeliveries()" small color="primary">
              Ver entregas
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <div>
          <h4>
            <v-icon left>mdi-cash-usd</v-icon>
            Pagamentos
          </h4>

          <small class="ml-auto" v-if="sale.account_transaction && canShow">
            {{ sale.account_transaction.account.name }}-
            {{ $format.dateBr(sale.account_transaction.date) }}
          </small>
        </div>
        <v-row>
          <v-col>
            <v-list dense v-if="hasPaymentTerms">
              <template v-for="(sale_bill, index) in sale.sale_payment_terms">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        :color="
                          getPaymentMethod(
                            sale_bill.payment_term.payment_method
                          ).color
                        "
                        v-text="
                          getPaymentMethod(
                            sale_bill.payment_term.payment_method
                          ).icon
                        "
                        left
                      />

                      {{ sale_bill.payment_term.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ $format.moneyBr(sale_bill.value) }}
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <v-list dense v-else>
              <template v-for="(bill, index) in sale.sale_bills">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        :color="getPaymentMethod(bill.payment_method).color"
                        v-text="getPaymentMethod(bill.payment_method).icon"
                        left
                      />
                      {{ getPaymentMethod(bill.payment_method).label }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    {{ $format.moneyBr(bill.value) }}
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
        <v-divider class="my-4" />
        <!-- <h5><v-icon left>mdi-cash-usd</v-icon>Total</h5> -->
        <v-row>
          <v-col class="d-flex flex-column text-right">
            <h3>Subtotal :{{ $format.moneyBr(sale.product_total) }}</h3>
            <h3>
              Desconto : {{ $format.moneyBr(sale.discount) }}
              <small>( {{ $format.decimal(sale.discount_percent) }}% )</small>
            </h3>
            <h3>Total : {{ $format.moneyBr(sale.net_total) }}</h3>
          </v-col>
        </v-row>
        <v-row v-if="canSeeProfitsMargin()">
          <v-col class="d-flex justify-end">
            <div class="d-flex flex-column">
              <div class="d-flex justify-space-between">
                <div class="mr-10">
                  <span class="text--secondary">Base Calculo:</span>
                </div>
                <div>
                  <span class="text--secondary">
                    {{ $format.decimal(sale.net_price_cash_total) }}
                  </span>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="mr-10">
                  <span class="text--secondary">Lucro:</span>
                </div>
                <div>
                  <span class="text--secondary">
                    {{ $format.decimal(sale.profit) }}
                    ({{ $format.decimal(sale.profit_percent) }}%)
                  </span>
                </div>
              </div>
            </div>

            <!-- Base Calculo: {{ $format.moneyBr(sale.net_price_cash_total) }}
            <br>
            Margem de lucro: {{ $format.decimal(sale.profit_percent) }}%
            <br>
            Lucro: {{ $format.decimal(sale.profit) }} -->
          </v-col>
        </v-row>
        <v-row v-if="$acl.can('userAproveSale')">
          <v-col class="text-right">
            <v-btn
              class="text-capitalize"
              small
              @click="showProfitMargins = !showProfitMargins"
              color="primary"
              text
            >
              Exibir margens
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <SaleStatus :value="sale.status" />
          </v-col>
        </v-row>
        <v-row v-if="sale.notes">
          <v-col>
            <h4>Observações</h4>
            {{ sale.notes }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          v-if="canUserAproveSale()"
          @click="handleAproveSale()"
        >
          Aprovar venda
        </v-btn>
        <v-spacer />
        <v-btn text color="secondary" @click="dialog = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SaleStatus from "./SaleStatus.vue";
import paymentMethods from "@/enums/paymentMethods.js";
import AppShowPhones from "../../app/sections/AppShowPhones.vue";
import CompanyChip from "../../company/sections/CompanyChip.vue";
import saleValidator from "@/services/sale/saleValidator.js";

export default {
  components: { AppShowPhones, CompanyChip, SaleStatus },
  data() {
    return {
      errorMessages: [],
      paymentMethods: paymentMethods,
      dialog: false,
      showProfitMargins: false,
      sale: {
        approved_by: {},
        id: null,
        company: {},
        salesman: {},
        customer: {},
        customer_stats: {},
        operation: {},
        sale_products: [],
        sale_bills: [],
        sale_payment_terms: [],
        account_transactions: [],
      },
    };
  },

  computed: {
    hasCustomer() {
      return this.sale.customer_id;
    },
    hasPaymentTerms() {
      return this.sale.sale_payment_terms.length > 0;
    },

    title() {
      return this.hasCustomer
        ? `#${this.sale.customer.code}- ${this.sale.customer.name}`
        : "Consumidor Final";
    },
    hasDelivery() {
      return (
        this.sale.delivery_type === "delivery" &&
        this.$acl.can("deliveriesIndex")
      );
    },
    canShow() {
      return this.$acl.isCashier() || this.$acl.isAdministrativeAssistant();
    },
  },

  created() {},

  methods: {
    open(sale_id) {
      this.sale.id = sale_id;

      this.loadSale(sale_id);

      this.dialog = true;
    },

    async loadSale(id) {
      var id = id || this.sale.id;

      this.$loading.start();

      await this.$http
        .show("sale/sale", id)
        .then(async (response) => {
          this.sale = response.sale;

          if (this.sale.customer_id) {
            await this.loadCustomer();
          }
          this.getErrorMessages();
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },

    getPaymentMethod(payment_method) {
      // console.log(this.paymentMethods);
      return this.paymentMethods.find(
        (method) => method.value == payment_method
      );
    },

    canUserAproveSale() {
      return (
        this.$acl.can("userAproveSale") &&
        this.sale.status == "waiting_approval"
      );
    },

    canSeeProfitsMargin() {
      return this.$acl.can("userAproveSale") && this.showProfitMargins == true;
    },

    handleAproveSale() {
      this.$loading.start();

      this.$http
        .update("sale/approve-sale", this.sale.id)
        .then((response) => {
          this.loadSale(this.sale.id);
          this.$emit("store");
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },

    handleViewDeliveries() {
      window.open(
        `/vendas/visualizar-entrega/?sale_number=${this.sale.number}`,
        "_blank"
      );
    },

    async loadCustomer() {
      await this.$http
        .show("customer/customer", this.sale.customer_id)
        .then((response) => {
          this.sale.customer = response.customer;
          [(this.sale.customer_stats = response.stats)];
        });
    },

    getErrorMessages() {
      this.errorMessages = saleValidator(this.sale);
    },
  },
};
</script>

<style></style>
