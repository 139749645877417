export default [
  {
    path: '/vendas/venda/novo',
    name: 'storeSaleForm',
    meta: { layout: 'appLayout', displayName: 'Nova Venda', permission: 'salesCreateEdit' },
    component: () => import('@/views/sales/StoreSaleForm')
  },
  {
    path: '/vendas/venda/editar/:id',
    name: 'updateSale',
    meta: { layout: 'appLayout', displayName: 'Editar Venda', permission: 'salesCreateEdit' },
    component: () => import('@/views/sales/StoreSaleForm')
  },
  {
    path: '/vendas/devolucao/novo',
    name: 'storeSaleReturning',
    meta: { layout: 'appLayout', displayName: 'Nova Devolução', permission: 'salesCreateEdit' },
    component: () => import('@/views/sales/StoreReturningForm')
  },
  {
    path: '/vendas/devolucao/editar/:id',
    name: 'updateSaleReturning',
    meta: { layout: 'appLayout', displayName: 'Editar Devolução', permission: 'salesCreateEdit' },
    component: () => import('@/views/sales/StoreReturningForm')
  },
  {
    path: '/vendas/venda/imprimir/:id',
    name: 'printSaleIndex',
    meta: { layout: 'printLayout', displayName: 'Imprimir Venda', },
    component: () => import('@/views/sales/PrintSaleIndex')
  },
  {
    path: '/vendas',
    name: 'salesIndex',
    meta: { layout: 'appLayout', displayName: 'Vendas', icon: 'mdi-cart-variant', permission: 'salesIndex' },
    component: () => import('@/views/sales/SalesIndex')
  },
  {
    path: '/vendas/relatorio-de-vendas-por-marca',
    name: 'sales-by-brand-report',
    meta: { layout: 'appLayout', displayName: 'Relatório de Vendas Por Marca', icon: 'mdi-cart-variant', permission: 'salesReport' },
    component: () => import('@/views/sales/SalesByBrandReport')
  },
  {
    path: '/comissao',
    name: 'commissionIndex',
    meta: { layout: 'appLayout', displayName: 'Comissão', icon: 'mdi-cart-variant', permission: 'commissionIndex' },
    component: () => import('@/views/sales/CommissionIndex')
  },
  {
    path: '/formas-de-pagamento',
    name: 'paymentTermsIndex',
    meta: { layout: 'appLayout', displayName: 'Formas de Pagamento', icon: 'mdi-domain', permission: 'paymentTermsIndex' },
    component: () => import('@/views/sales/PaymentTermsIndex')
  },
]